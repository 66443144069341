import React, {useState} from 'react';
import CountCart from './CountCart/CountCart'
import './Cart.css'
import {useFormAndValidation} from '../../hooks/useFormAndValidation'
import Delivery from '../Delivery/Delivery';
import Preloader from '../Preloader/Preloader';


function Cart({orders, handleChangeQuantity, deleteProduct, handleAddOrder, isLoading}) {
  const formRef = React.useRef() // доступ к форм для начальной валидации формы
  const queryResult = JSON.parse(localStorage.getItem('queryResult')); // получаем из хранилища раннее введенные контактные данные
  const {street="", phoneNumber="", house="", commentary=""} = queryResult || {}
  let {checkValid, values, handleChange,  errors, isValid} = useFormAndValidation({
    street: !street ? '': street,
    phoneNumber: !phoneNumber ? '': phoneNumber,
    commentary: !commentary ? '': commentary,
    house: !house ? '': house,
  });
  const [total, setTotal] = React.useState({
    price: orders.reduce((prev, curr)=> prev + curr.sum, 0),
    count: orders.reduce((prev, curr)=> prev + curr.count, 0)
  })

  const [deliveryAmount, setDeliveryAmount] = useState(0)

  const handleSubmit = (e) => {
    e.preventDefault();
    handleAddOrder (
      {
        id: 5,
        orders: orders,
        values: values,
        deliveryAmount: deliveryAmount,
        totalAmount: total.price
      }
    )
  }
  React.useEffect(() => {
    checkValid(formRef.current)
  }, [])

  React.useEffect(() => {
    setTotal({
      price: orders.reduce((prev, curr) => prev + curr.sum, 0),
      count: orders.reduce((prev, curr) => prev + Number(curr.count) , 0),
    });
  }, [orders])

  React.useEffect(()=> {
    if (total.price < 2000) {
      setDeliveryAmount(100)
    }
    else {
      setDeliveryAmount(0)
    }
  })
  ///////////////////////////
	return (
		<section className="cart">
      <Preloader isLoading={isLoading}/>
      {!isLoading &&
      <>
      <h2 className='cart__title'>Корзина</h2>
      {orders.length>0 &&
      <div className="product cart__container">
        <div className='cart__name'>наименование</div>
        <div className='cart__name'>количество</div>
        <div className='cart__name'>стоимость</div>
			</div>}

      {orders.map((product)=> {
        return(
          <div className='product' key={product._id}>
            {/* <img src={product.link} alt={product.name} className='product__img'/> */}
            <div className='product__title-cart'>{product.name}</div>
             <CountCart product={product} handleChangeQuantity={handleChangeQuantity} orders={orders}/>
            <div className='product__controls'>
              <button type='button' className='product__btn-del' onClick={()=>{deleteProduct(product._id);}}>
                -
              </button>
            </div>
          </div>
        )
      })
      }
    {orders.length>0 &&
      <div className='cart__footer'>
        <div className='product cart__container'>
          <p className='cart__total-text'>Всего</p>
         
          <p className='cart__total-text'>{total.count}</p>
          <p className='cart__total-text'>{total.price}</p> 
        </div>
        <div className='product'>
          <p className='cart__total-text'>Доставка: </p>
          <p></p>
          <p className='cart__total-text'>{deliveryAmount}</p>
        </div>
        <div className='product'>
          <p className='cart__total-text'>ИТОГО: </p>
          <p></p>
          <p className='cart__total-text'>{ deliveryAmount + total.price } руб.</p>
        </div>
      </div>
    }
    {orders.length ===0 &&
    <p>Нет товаров</p>
    }
    <div className='cart__container-bottom'>
      <Delivery isLine={false}/>
      <form ref={formRef} className='order-info' onSubmit={handleSubmit} name="form-order-info" noValidate>
        <span className='order-info__title'>Заполните контактные данные</span>
        <input className='order-info__input order-info__input-adress'
          name="phoneNumber"
          value={values.phoneNumber}
          onChange={handleChange}
          required
          placeholder="Номер телефона"
          minLength={5}
          // type='number'
          //pattern='/\+7\(\d{3}\)\d{3}-\d{2}-\d{2}'
        />
        <span className="input-error">{errors.phoneNumber}</span>
        <input className='order-info__input order-info__street'
          name="street" value={values.street}
          onChange={handleChange}
          required
          placeholder="Улица"
        />
        <span className="input-error">{errors.address}</span>
        <input className='order-info__input order-info__phone-number'
          name="house" value={values.house}
          onChange={handleChange}
          onInput={handleChange}
          minLength={1}
          required
          placeholder="Дом/квартира"
        />
        <span className="input-error">{errors.house}</span>
        <input className='order-info__input order-info__phone-number'
          name="commentary"
          value={values.commentary}
          onChange={handleChange}
          placeholder="Комментарий к заказу"
        />
        <span className="input-error">{errors.commentary}</span>
        <button type="submit"
          className={`cart__button-submit ${((total.count===0) || (!isValid)) ?"cart__button-submit_status_disabled":""}`}
          disabled={total.count===0}>
            Оформить заказ
        </button>
      </form>
  
    </div>
      </>
      }

      
      

    </section>
	);
}

export default Cart;
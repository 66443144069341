import React, {useRef} from 'react';
import './AddProdect.css'
import {useFormAndValidation} from '../../hooks/useFormAndValidationIn'
import Preloader from '../Preloader/Preloader';



function Add({ groups, addCard, isLoading, units }) {
  const fileRef = useRef(null);
  const {values, handleChange,  errors, isValid} = useFormAndValidation({
    name: "",
    description: "",
    group: "",
    price: 0,
    unit: "",
    id1c: 0
  })

  const handleSubmit = (event) => {
    event.preventDefault();
    if(!fileRef.current)  {
      addCard([], values)
    }
    else{
      const reader = new FileReader();
      reader.onloadend = () => {
        const uint8Array = new Uint8Array(reader.result);
        addCard(uint8Array, values)
      };
      // рекомендованный метод
      reader.readAsArrayBuffer(fileRef.current[0]);
    }
    //return void null;

    }

  const nodeDom = (
    <>
      <Preloader isLoading={isLoading}/>
      {!isLoading &&
      <form onSubmit={handleSubmit} className='add-card'>
      <input
        className="add-card__inp-change"
        onChange={e => fileRef.current = e.target.files}
        accept="image/*"
        type="file"
        id="button-file"
        // required
      />
      <span>Наименование</span>
      <input 
        name="name"
        type="text"
        value={values.name}
        onChange={handleChange}
        minLength={2}
        required
      />
        <span>Код товара в 1с</span>
      <input 
        name="id1c"
        type="number"
        value={values.id1c}
        onChange={handleChange}
        minLength={2}
        required
      />
      {/* <span>Описание</span>
      <input 
        id="description" name="description"
        type="text"
        value={values.description}
        onChange={handleChange}
      /> */}
      <span>Цена</span>
        <input 
        name="price"
        type="number"
        value={values.price}
        onChange={handleChange}
        minLength={2}
      />
      <span>Группа</span>
      <select name="group" size="1" onChange={handleChange} required defaultValue={'Выбор группы'}>
      <option  value='Выбор группы' disabled>Выберите группу</option>
        {groups.map((group)=> {
          return(
          <option key={group._id} value={group._id}>{group.name}</option>
          )
        })
        }
    </select>

    <span>Единица измерения</span>
      <select name="unit" size="1" onChange={handleChange} required defaultValue={'Выбор группы'}>
      <option  value='Выбор группы' disabled>Единица измерения</option>
        {units.map((unit)=> {
          return(
          <option key={unit._id} value={unit._id}>{unit.name}</option>
          )
        })
        }
    </select>
    
    <button type="submit" className={`add-card__btn-submit ${!isValid&&"add-card__btn-submit_status_disabled" }`}>Сохранить</button>
  </form>
      }
      
      </>
    
  );

  return nodeDom
}

export default Add;
import {useFormAndValidation} from '../../hooks/useFormAndValidationIn'
import './Feedback.css'

function FeedBack ({feedback}) {
  const { values, handleChange,  errors, isValid} = useFormAndValidation({
    name: "",
    organization: "",
    phoneNumber: ""
  });

  const handleSubmit = (e)=> {
    e.preventDefault();
    feedback(values);
  }
  return (
    <div className='feetback'>
      <h2 className='feedback__info'>Предлагаем индивидуальные условия по договору поставки, оплата со счета организации, отсрочка платежей. Позвоните нам или заполните форму для обратного звонка</h2>

      <form className='feetback-form' onSubmit={handleSubmit}>
      <h2 className="feetback__form-title">Заполните контактные данные</h2>
        <input className='input-data'
          name="organization"
          value={values.organization}
          onChange={handleChange}
          required
          placeholder="Наименование организации"
        />
        <span className="input-error">{errors.organization}</span>

        <input className='input-data'
          name="name"
          value={values.name}
          onChange={handleChange}
          required
          placeholder="Контактное лицо"
        />
        <span className="input-error">{errors.name}</span>
   
        <input className='input-data'
          name="phoneNumber"
          value={values.phoneNumber}
          onChange={handleChange}
          required
          placeholder="Номер телефона"
          minLength={1}
        />
        <span className="input-error">{errors.phoneNumber}</span>
        <button type="submit" className={`feetback__button-submit ${!isValid?"feetback_button-submit_status_disabled":""}`} disabled={!isValid}>Отправить</button> 
      </form>
    </div>
  )
}

export default FeedBack
import React from 'react';
import Menu from '../Menu/Menu';
import SimpleSlider from '../Slider/Slider'
import AboutUs from '../AboutUs/AboutUs';
import Delivery from '../Delivery/Delivery';
import Footer from '../Footer/Footer';
import Contacts from '../Contacts/Contacts';
import ProductCatalog from '../ProductCatalog/ProductCatalog';
import { Link } from 'react-router-dom';
import './Main.css'


function Main ({products, isLoading, groups, handleSubmitFormSearch, addOrder,  deleteProduct, card,  setCard, setProducts, loggedIn, orders, addClass, strSearch, classCart, wholesale}) {

  return (
    <>
      <main className="main">
        {loggedIn && <Menu />}
        <nav className="menu-bottom">
            <ul className='menu-bottom__list'>
              <li><a href='#delivery'className='menu-bottom__link'><p className='menu-bottom__text'>{"Доставка \n     100₽"}</p></a></li>
              <li><Link to='/feedback' className='menu-bottom__link'><p className='menu-bottom__text'>Бизнесу</p></Link></li>
              <li><a href='#contacts' className='menu-bottom__link'><p className='menu-bottom__text'>Контакты</p></a></li>
            </ul>
          </nav>
        {/* <SimpleSlider products={products}/> */}
    
        <ProductCatalog 
          products={products}
          isLoading={isLoading}
          groups={groups}
          handleSubmitFormSearch={handleSubmitFormSearch}
          addOrder={addOrder}
          deleteProduct={deleteProduct}
          card={card}
          setCard={setCard}
          setProducts={setProducts}
          loggedIn={loggedIn}
          orders={orders}
          strSearch={strSearch}
          addClass={addClass}
          classCart={classCart}
          wholesale={wholesale}/>
        <AboutUs />
        <Delivery isLine={true}/>
        {/* <Contacts /> */}
        <Footer />
      </main>
    </>
  );
}

export default Main;
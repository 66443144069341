import {Link} from 'react-router-dom';
import React from 'react';
import shoppingcart from '../../images/shoppingcart.png'
import './CartBlock.css'

function CartBlock ({priceTotal, classCart}) {

  return (
       <div className='header__cart-container'>
          <Link to="/cart" className={`header__cart ${classCart}`}>
            <img src={shoppingcart} alt='Корзина' className='header__cart-img'></img>
            <p className='header__price-total'>{/* {orders.length}*/} ₽ {priceTotal} </p>
          </Link>
        </div>
  )
}

export default CartBlock;
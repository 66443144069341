import React from 'react';
import './Menu.css';
import { Link } from 'react-router-dom';
function Menu() {

  return ( 
      <nav className="main-menu">
        <ul className='main-menu__list'>
          <li><Link to='/add'>Добавить новый товар</Link></li>
          <li><Link to='/group'>Группы товаров</Link></li>
          <li><Link to='/unit'>Единицы измерения</Link></li>
        </ul>
      </nav>
  );
}
export default Menu;

export function wordSearchStr(arr, str, groupValue) {   // поиск по части строки в массиве
  let arrFilter = [];
  const indexList = [];
  if (groupValue !== "ВсеКатегории") {
    arrFilter = Object.assign(arr.filter((i) => i.group.name === groupValue))
  }
  else {
    arrFilter = Object.assign([], arr); 
  }
  for (let i = 0; i < arrFilter.length; i++) {
      if (arrFilter[i].name.toLowerCase().indexOf(str.toLowerCase())!==(-1)) {
        indexList.push(arrFilter[i]);
      }
    }
  return indexList
}

//Проверяем есть ли товар в корзине
export function contains(arr, elem) {
  for (var i = 0; i < arr.length; i++) {
      if (arr[i].id === elem.id) {
        arr[i].sum =arr[i].sum + elem.sum 
        arr[i].count =arr[i].count + elem.count 
           return true;
      }
  }
  return false;
}


import "./Count.css"
import editInc from '../../../images/editInc.svg';
import editDec from '../../../images/editDec.svg';

// const Count = ({ count, increase, decrease, changeValue, id }) => {
	const Count = ({ count, increase, decrease, changeValue }) => {
	return (
		<div className='count'>
			<button type='button' className='count__button' onClick={decrease}><img src={editDec} alt='Уменьшить' className='count__button-img'/></button>
			<input onChange={(e)=>{changeValue(e.target.value)}} type='number' className='count__input' min='1' max='100' value={count} />
			<button type='button' className='count__button' onClick={increase}><img src={editInc} alt='Увеличить' className='count__button-img'/></button>
		</div>
	);
};

export default Count;
import './CatalogGroup.css'

function CatalogGroup({groups, chengeValue, inputRef}) {

  return(
    
    <div className='product-catalog__group'>
      <swiper-container>
  
      </swiper-container>
    <label className='group-container'>
      <input className='switch2' type="radio" name="products" value={"ВсеКатегории"} onChange={chengeValue} defaultChecked={true} ref={inputRef}/>
      <div class="stlradio"><p className='switch2__text'>Все категории</p> </div>
        {/* <p className='switch2__text'>Все категории</p> */}
    </label>
    {Array.from(groups).map((item)=> {
      return(
        <label key = {item} className='group-container'>
          <input className='switch2' type="radio" name="products" value={item} onChange={chengeValue}/>
            <div class="stlradio"><p className='switch2__text'>{item}</p></div>
            {/* <p className='switch2__text'>{item}</p> */}
        </label>
      )
    })}
  </div>


  )
}

export default CatalogGroup
import { useLocation } from "react-router-dom";
import React, {useRef, useState} from 'react';
import {useFormAndValidation} from '../../hooks/useFormAndValidationIn'
import './EditCard.css'



function EditCard({updateCard, deleteCard, units, groups}) {

  const fileRef = useRef(null);
  const { state } = useLocation();
  const [checked, setChecked] = useState(state.isShow)
  const [checkedWholesale, setCheckedWholesale] = useState(state.wholesaleShow)
  const {values, handleChange, errors, isValid} = useFormAndValidation({
    price: state.price,
    name: state.name,
    isShow: state.isShow,
    nomenclatureId: state.nomenclatureId,
    group: state.group,
    unit: state.unit,
    wholesaleShow:  state.wholesaleShow,
    wholesalePrice: state.wholesalePrice
 });

  const handleSubmit = (event) => {
    event.preventDefault();
    state.price = values.price;
    state.name = values.name;
    state.group = values.group;
    state.isShow = checked;
    state.nomenclatureId = values.nomenclatureId;
    state.unit = values.unit;
    state.wholesaleShow = checkedWholesale;
    state.wholesalePrice = values.wholesalePrice

    if(!fileRef.current)  {
      updateCard([], state)
    }
    else{
      const reader = new FileReader();
      reader.onloadend = () => {
        const uint8Array = new Uint8Array(reader.result);
        updateCard(uint8Array, state)
      };
      // рекомендованный метод
      reader.readAsArrayBuffer(fileRef.current[0]);
    }
    // updateCard(state)
  }

  const handleChangeWholesaleShow = (e) => {
    setCheckedWholesale(e.target.checked)
  }
  const handleChangeChek = (e) => {
    setChecked(e.target.checked)
  }

  const handleDeleteCard = () => {
    deleteCard(state)
  }

  return  (
    <form onSubmit={handleSubmit} className="edit-card">
      <span>Текущие данные</span>
      <div className="edit-card__data">
        <span className="input-title">Наименование</span>
        <input 
          name='old-name'
          value={state.name}
          disabled={true}
        />
        <span className="input-title">Цена</span>
        <input 
          name='old-price'
          value={state.price}
          disabled={true}
        />
      </div>
      <span className="input-title">Новые данные</span>
      <div className="edit-card__data">
        <span className="input-title">Новая цена</span>
        <input 
          name='price'
          value={values.price}
          type="number"
          onChange={handleChange}
        />
        <span className="edit-card__input-err">{errors.price}</span>

        <span className="input-title">Новое наименование</span>
        <input 
          minLength={2}
          name='name'
          value={values.name}
          onChange={handleChange}
        />
        <span className="edit-card__input-err">{errors.name}</span>

        <span className="input-title">Код 1с</span>
        <input 
          minLength={1}
          name='nomenclatureId'
          value={values.nomenclatureId}
          onChange={handleChange}
        />
        <span className="edit-card__input-err">{errors.nomenclatureId}</span>

        <span className="input-title">Показывать в каталоге</span>
        <input 
          className="checkbox"
          type="checkbox"
          name='isShow'
          value={values.isShow}
          onChange={handleChangeChek}
          checked = {checked}
        /> 
        <span className="edit-card__input-err">{errors.nomenclatureId}</span>

        <span className="input-title">Показывать в оптовом каталоге</span>
        <input 
          className="checkbox"
          type="checkbox"
          name='isShowWholesale'
          value={values.wholesaleShow}
          onChange={handleChangeWholesaleShow}
          checked = {checkedWholesale}
        /> 
        <span className="edit-card__input-err">{errors.nomenclatureId}</span>
        
        <span className="input-title">Новая оптовая цена</span>
        <input 
          name='wholesalePrice'
          value={values.wholesalePrice}
          type="number"
          onChange={handleChange}
        />

        <input
        className="add-card__inp-change"
        onChange={e => fileRef.current = e.target.files}
        accept="image/*"
        type="file"
        id="button-file"
        // required
        />
        <span className="input-title">Группа</span>
        <select name="group" size="1" onChange={handleChange} required defaultValue={'Выбор группы'}>
          {groups.map((group)=> {
            return(
            <option key={group._id} value={group._id}>{group.name}</option>
            )
          })
          }
        </select>
        <span className="input-title">Единица измерения</span>
        <select name="unit" size="1" onChange={handleChange} required defaultValue={'Выбор группы'}>
          {units.map((unit)=> {
            return(
            <option key={unit._id} value={unit._id}>{unit.name}</option>
            )
          })
          }
        </select>

      </div>
      <button type="submit" className="edit-card__btn edit-card__btn-submit">Изменить</button>
      <button type='button' className='edit-card__btn edit-card__btn-del' onClick={handleDeleteCard}>Удалить карточку</button>
    </form>
    
  )
}

export default EditCard;
import './FormSearch.css'

function FormSearch ({value, handleChange }) {
  return (
    <div className="form-search">
      <input className={`form-search__input` } placeholder="Название продукта" required id="products" type="text" value={value} onChange={handleChange}/>
      <button type="submit" className="form-search__button-submit"></button>
  </div>
  )
}

export default FormSearch
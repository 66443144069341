import React, { useState, useEffect } from 'react';
import './App.css';
import { Routes, Route, useNavigate, Navigate} from 'react-router-dom';
import Main from '../Main/Main';
import Header from '../Header/Header';
import Cart from '../Cart/Cart';
import Login from '../Login/Login';
// import Register from '../Register/Register';
import AddProduct from '../AddProduct/AddProduct';
import EditCard from '../EditCard/EditCard'
import AddGroup from '../Group/Group';
import AddUnit from '../Unit/Unit'
import PopupInfo from '../Popup/PopupInfo';
import PageNotFound from '../PageNotFound/PageNotFound';
import ProtectedRouteElement from '../ProtectedRoute/ProtectedRoute.js';
import  { api } from '../utils/Api'
import {wordSearchStr, contains} from '../utils/helperFunction';
import {CurrentUserContext} from '../../contexts/CurrentUserContext';
import Product from '../Card/Card';
import FeedBack from '../Feedback/Feedback.js';
import {useSWRQuery} from '../../hooks/use-swr.js';
import ProductCatalog from '../ProductCatalog/ProductCatalog';


function App() {
  const navigate = useNavigate()
  const [products, setProducts] = useState([])
  const [groups, setGroups] = useState([])
  const [units, setUnits] = useState([])
  const [foundProducts, setFoundProducts] = useState(products)
  const [orders, setOrders] = useState([])
  const [card, setCard] =  useState(0); //Количество в корзине
  const [isLoading, setIsLoading] = React.useState(false) // для прелоадера
  const [isPopupInfoOpen, setIsPopupInfoOpen] = useState({
    isPopupInfoOpen: false,
    message: ""
  })
  const [isLockForm, setIsLockForm] = useState(false);
  const [loggedIn, setLoggedIn] = useState(false);
  const [currentUser, setCurrentUser] = useState({});
  const [strSearch, setStrSearch] = useState('');

  const { data, error, isLoading2 } = useSWRQuery('https://www.ovo-m.ru/api/cards`');
  
  const groupLists = products.map((item)=>{ //создаем массив из групп
    return item.group.name
  })
  //const uniqueGroups1 = new Set(groupLists); // создаём коллекцию уникальных значений. переводим в массив, и сортируем по алфавиту
  const uniqueGroups = Array.from(new Set(groupLists)).sort()
  function getData(isShow) {
    setIsLoading(true)
    Promise.all([
      api.getCards(isShow),
      api.getUnits(),
      api.getGroups()
    ])
    .then(([cards, units, groups]) => {
      setIsLoading(false)
      setProducts(cards);
      setFoundProducts(cards);
      setGroups(groups);
      setUnits(units);
    })
    .catch(err=> console.log(err))
  }

  useEffect(() => {
    const token = localStorage.getItem('jwt')
    if (!token){
      setLoggedIn(false)
      getData(false)
      return
    }
    api.checkToken(token)
      .then((user) => {
        if (user){
          setLoggedIn(true);
          setCurrentUser(user)
          getData(true);
          const interval = setInterval(()=> {
            setStrSearch("")
            getData()
          }, 300000);
        }
      })
      .catch(err => {
        console.log(err)
      })
    }, [loggedIn]
  )

  function getCatch(err) {
    err
    .then((result) => {
      setIsLoading(false)
      setIsPopupInfoOpen({
        isPopupInfoOpen: true,
        message: result.message
      })
    })
    .catch(err => {
      console.log(err)
  })
  }

  function handleSubmitFormSearch(value, groupValue) { //Поиск карточки по строке
    setStrSearch(value)
    const indexList =  wordSearchStr (products, value, groupValue);
    setFoundProducts(indexList)
  }

  function addOrder(item) {
    if (contains(orders, item)) {
      setOrders([...orders])
    }
    else {
      setOrders([...orders, item])
    }
  }

  function addCard(uint8Array, values) {
    setIsLoading(true)
    api.addCard(uint8Array, values)
    .then((card)=> {
      setIsLoading(false)
      setIsPopupInfoOpen({
        isPopupInfoOpen: true,
        message: `Товар "${card.name}" успешно создан` 
      })
      navigate('/', {replace: true});
    })
    .catch(getCatch)
  }
    //Обновление карточки товара
    function updateCard (uint8Array, values) {
      api.updateCard(uint8Array, values)
      .then((card)=> {
        setIsPopupInfoOpen({
          isPopupInfoOpen: true,
          message: `Данные успешно изменены, новая цена "${card.name}" - ${card.price}`
        })
        setFoundProducts((state) => state.map((c) => c._id === card._id ? card: c))
        navigate('/', {replace: true});
      })
      .catch(getCatch)
    }

     //Удаление карточки товара
     function deleteCard (data) {
      api.deleteCard({data: data})
      .then((card)=> {
        setIsPopupInfoOpen({
          isPopupInfoOpen: true,
          message: `Товар "${card.name}" успешно удален`
        })
        navigate('/', {replace: true});
      })
      .catch(getCatch)
    }

  function addGroup (data) {
    api.addGroup(data)
    .then((group)=> {
      console.log(group)
      setIsPopupInfoOpen({
        isPopupInfoOpen: true,
        message: `Группа "${group.name}" успешно добавлена` 
      })
    })
    .catch(getCatch)
  }

  function addUnit (data) {
    api.addUnit(data)
    .then((unit)=> {
      setIsPopupInfoOpen({
        isPopupInfoOpen: true,
        message: `Единица измерения "${unit.name}" успешно добавлена` 
      })
    })
    .catch(getCatch)
  }

  function handleChangeQuantity (product, isIncrease, value = false) {
    const {count,  price, id } = product; //
    if (!value) {let num = 0;
      if (isIncrease) {
        num = 1;
      }
      else {
        num=(-1)
      }
      setOrders((state) => state.map((c) => c.id === id ? ({
        ...c,
        count: count + num,
        sum: (count + num)  * price, 
      }) : c))}
    else{
      setOrders((state) => state.map((c) => c.id === id ? ({
        ...c,
        count: Number(value),
        sum: value  * price, 
      }) : c))
    }
  } 

  function deleteProduct(id) {
    setOrders((cart) => cart.filter((product)=> id !== product._id));
  }

  function handleAddOrder(data) { //добавление нового заказа
    setIsLoading(true)
    api.addNewOrder(data)
    .then((newCard)=> {
      setIsLoading(false)
      setIsPopupInfoOpen({
        isPopupInfoOpen: true,
        message: "Заказ успешно отправлен, в ближайшее время менеджер с Вами свяжется по указанному номеру телефона для уточнения деталей заказа"
      })
      navigate('/', {replace: true});
      setOrders([])
    })
    .catch(err => {
      setIsLoading(false)
      console.log(err)
    })
  }

  function closeAllPopups() {   // Закрытие попапов
    setIsPopupInfoOpen({
      isPopupInfoOpen: false,
      message: ""
    })
  }

  function loginUser({email, password}) {
    setIsLockForm(true)
    api.login(password, email)
      .then((data) => {
        if (data.token){
          setLoggedIn(true) 
          localStorage.setItem('jwt', data.token);
          navigate('/', {replace: true});
          setCurrentUser({
          name: data.name,
          email: email,
         })
         setIsLockForm(false)
       }
     })
     .catch(err => {
        err
        .then((result) => {
          setIsPopupInfoOpen({
            isPopupInfoOpen: true,
            message: result.message
          })
        })
        .catch(err => {  
          console.log(err)
      })
      setIsLockForm(false)
     })
    }

    // function registerUser({name, email, password}) {
    //   api.register(name, password, email)
    //   .then(() => {
    //    // loginUser({email, password})
    //   })
    //   .catch(err => {
    //     err
    //     .then((result) => {
    //       setIsPopupInfoOpen({
    //         isPopupInfoOpen: true,
    //         message: result.message
    //       })
    //     })
    //     .catch(err => {
    //       console.log(err)
    //   })
    //  })
    // }

    function signOut(){ //Выход из системы
      localStorage.removeItem('jwt');
      setLoggedIn(false)
      navigate("/", {replace: true})
    }

    const [classCart, setClassCart] = useState('')
    function addClass() {
      setClassCart('header__cart-anim');
      setTimeout(()=> setClassCart(''), 500)
     }
  

    function feedback(data) {
      console.log(data)
      api.feedback(data)
      .then((unit)=> {
        setIsPopupInfoOpen({
          isPopupInfoOpen: true,
          message: `В ближайшее время менеджер позвонит Вам` 
        })
      })
      .catch(getCatch)
    }
    // const [scroll, setScroll] = React.useState(0);

    // const handleScroll = () => {
    //   setScroll(window.scrollY);
    //   console.log(window.scrollY)
    // };
  
  
    // React.useEffect(() => {
    //   window.addEventListener("scroll", handleScroll);
    //   return () => window.removeEventListener("scroll", handleScroll);
    // }, []);

  return (
    <div className="page">
      <CurrentUserContext.Provider value={currentUser}>
      <Header orders={orders} signOut={signOut} loggedIn={loggedIn}/>
      <Routes>
        {/* <Route path="/signup" element={!loggedIn ? <Register registerUser={registerUser}  isLockForm={isLockForm}/> : <Navigate to="/movies" />} /> */}
        <Route path="/signin" element={!loggedIn ? <Login loginUser={loginUser} isLockForm={isLockForm}/>: <Navigate to="/" />} />
        <Route path="/" element={<Main 
          products={foundProducts}
          isLoading={isLoading}
          groups={uniqueGroups}
          handleSubmitFormSearch={handleSubmitFormSearch}
          addOrder={addOrder}
          deleteProduct={deleteProduct}
          card={card}
          setCard={setCard}
          setProducts={setProducts}
          loggedIn={loggedIn}
          orders={orders} 
          strSearch={strSearch} 
          addClass={addClass}
          classCart={classCart}  
          wholesale={false} 
        />} />
        <Route path="/add" element={<ProtectedRouteElement element={AddProduct} groups={groups} addCard={addCard} loggedIn={loggedIn} isLoading={isLoading} units={units}/>} />
        <Route path="/group" element={<ProtectedRouteElement element={AddGroup} addGroup={addGroup} loggedIn={loggedIn}/>} />
        <Route path="/wholesale" element={<ProductCatalog
          products={foundProducts}
          isLoading={isLoading}
          groups={uniqueGroups}
          handleSubmitFormSearch={handleSubmitFormSearch}
          addOrder={addOrder}
          deleteProduct={deleteProduct}
          card={card}
          setCard={setCard}
          setProducts={setProducts}
          loggedIn={loggedIn}
          orders={orders} 
          strSearch={strSearch} 
          addClass={addClass}
          classCart={classCart} 
          wholesale={true} 
        />} />
        <Route path="/unit" element={<ProtectedRouteElement element={AddUnit} addUnit={addUnit} loggedIn={loggedIn}/>} />
        <Route path="/edit-card" element={<ProtectedRouteElement element={EditCard} updateCard={updateCard} deleteCard={deleteCard} loggedIn={loggedIn} groups={groups} units={units}/>} />
        <Route path="/cart" element={<Cart orders={orders} addOrder={addOrder} handleChangeQuantity={handleChangeQuantity} deleteProduct={deleteProduct} handleAddOrder={handleAddOrder}   isLoading={isLoading}/>} />
        <Route path="*" element={<PageNotFound />} />
        <Route path="/product" element={<Product 
          addClass={addClass}
          classCart={classCart}
          orders={orders}
          addOrder={addOrder} />} />
        <Route path="/feedback" element={<FeedBack feedback={feedback}/>}/>
      </Routes>
      </CurrentUserContext.Provider>
      <PopupInfo isOpen={isPopupInfoOpen.isPopupInfoOpen} onClose={closeAllPopups} InfoTooltip={isPopupInfoOpen.message}/>
    </div>
  );
}

export default App;

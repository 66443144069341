import { BASE_URL } from "./constants";

const getJson = (res)=>{
  if (res.ok) {
    return res.json();
  }
  return Promise.reject(res.json());
}

const register = (name, password, email) => {
  return fetch(`${BASE_URL}/signup`, {
    method: 'POST',
    headers: {
      'Accept': 'application/json',
      'Content-Type': 'application/json'
    },
    body: JSON.stringify({name, password, email})
  })
  .then(getJson)
};

const login = (password, email) => {
  return fetch(`${BASE_URL}/signin`, {
    method: 'POST',
    headers: {
      'Accept': 'application/json',
      'Content-Type': 'application/json'
    },
    body: JSON.stringify({password, email})
  })
  .then(getJson)
};

const checkToken = (token_) => {
  return fetch(`${BASE_URL}/user/me`, {
    method: 'GET',
    headers: {
      'Accept': 'application/json',
      'Content-Type': 'application/json',
      authorization: `Bearer ${token_}`,
    }
  })
  .then(getJson)
}

const addProduct = (formData) => {
  const token = localStorage.getItem("jwt");
  return fetch(`${BASE_URL}/upload`, {
    method: 'POST',
    headers: {
      'Accept': 'application/json',
      "Content-type": "application/json",
      authorization: `Bearer ${token}`,
    },
    body: JSON.stringify(formData)
  })
  .then(getJson)
};


//добавление нового заказа
const addNewOrder = (data) => {
  return fetch(`${BASE_URL}/order`, {
    method: 'POST',
    headers: {
      'Accept': 'application/json',
      'Content-Type': 'application/json'
    },
    body: JSON.stringify(data)
  })
  .then(getJson)
}

// добавление нового товара
const addCard = (uint8Array, values) => {
  const token = localStorage.getItem("jwt");
  return fetch(`${BASE_URL}/nomenclature/upload`, {
    method: 'POST',
    headers: {
      'Accept': 'application/json',
      'Content-Type': 'application/json',
      authorization: `Bearer ${token}`,
    },
    body: JSON.stringify({
                img: [...uint8Array],
                value: values
              } )        
  })
  .then(getJson)
};

//Получение товаров
const getCards = (isShow) => {
  const token = localStorage.getItem("jwt");
  return fetch(`${BASE_URL}/cards`, {
    method: 'Post',
    headers: {
      'Accept': 'application/json',
      'Content-Type': 'application/json',
      authorization: `Bearer ${token}`,
    },
    body: JSON.stringify({
      isShow: isShow})
  })
  .then(getJson)
}

const updateCard = (uint8Array, data) => {
  const token = localStorage.getItem("jwt");
  return fetch(`${BASE_URL}/nomenclature`, {
    method: 'POST',
    headers: {
      'Accept': 'application/json',
      'Content-Type': 'application/json',
      authorization: `Bearer ${token}`,
    },
    // body: JSON.stringify(data)
    body: JSON.stringify({
      img: [...uint8Array],
      data: data
    } )  
  })
  .then(getJson)
}


const deleteCard = (data) => {
  const token = localStorage.getItem("jwt");
  return fetch(`${BASE_URL}/nomenclature/delete`, {
    method: 'POST',
    headers: {
      'Accept': 'application/json',
      'Content-Type': 'application/json',
      authorization: `Bearer ${token}`,
    },
    body: JSON.stringify(data)
  })
  .then(getJson)
}

const getGroups = () => {
  return fetch(`${BASE_URL}/groups`, {
    headers: {
      'Accept': 'application/json',
      'Content-Type': 'application/json'
    }
  })
  .then(getJson)
}

const getUnits = () => {
  return fetch(`${BASE_URL}/cards/unit`, {
    headers: {
      'Accept': 'application/json',
      'Content-Type': 'application/json'
    }
  })
  .then(getJson)
}


const addGroup = (data) => {
  const token = localStorage.getItem("jwt");
  return fetch(`${BASE_URL}/group`, {
    method: 'POST',
    headers: {
      'Accept': 'application/json',
      'Content-Type': 'application/json',
      authorization: `Bearer ${token}`,
    },
    body: JSON.stringify(data)
  })
  .then(getJson)
}


const addUnit = (data) => {
  const token = localStorage.getItem("jwt");
  return fetch(`${BASE_URL}/nomenclature/unit`, {
    method: 'POST',
    headers: {
      'Accept': 'application/json',
      'Content-Type': 'application/json',
      authorization: `Bearer ${token}`,
    },
    body: JSON.stringify(data)
  })
  .then(getJson)
}

const feedback = (data) => {
  return fetch(`${BASE_URL}/order/feedback`, {
    method: 'POST',
    headers: {
      'Accept': 'application/json',
      'Content-Type': 'application/json'
    },
    body: JSON.stringify(data)
  })
  .then(getJson)
}

export const api = {
  register,
  login,
  checkToken,
  addProduct,
  addNewOrder,
  addCard,
  getCards,
  updateCard,
  getGroups, 
  addGroup,
  deleteCard,
  addUnit,
  getUnits,
  feedback
}
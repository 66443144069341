import React from 'react';
import './Header.css';
import {Link, useNavigate} from 'react-router-dom';
import tg from '../../images/tg_color.svg';
import ph from '../../images/phone.png';
import logo from '../../images/logo3.png';
import whatsApp from '../../images/whatsapp.svg';
import {CurrentUserContext} from '../../contexts/CurrentUserContext'

function Header({loggedIn, signOut}) {
  const currentUser = React.useContext(CurrentUserContext);
  const navigate = useNavigate();

  return (
    <header className='header'>
      <div className='header__container'>
        <div className='header__social-block'>
          <Link to="/" className='header__logo'><img src={logo} alt='Логотип' className='header__logo-img'/></Link>
          <div className='header__contact-block'>
            <p className='header__info header__info_disabled'>c 9-00 до 21-00</p>
            <a href="tel:+79064744741" className='header__info'><img src={ph} className='header__icon' alt='Телефон'></img></a>
            <a href='https://t.me/ovomarketkmv' className="header__social-link" target="_blank"  rel="noreferrer"><img src={tg} className='header__icon' alt='Ссылка телеграмм'></img></a>
            <a href=' https://api.whatsapp.com/send?phone=79064744741' className="header__social-link" target="_blank"  rel="noreferrer"><img src={whatsApp} className='header__icon' alt='Ссылка телеграмм'></img></a> 
            
          </div>
          {/* <Link to="/wholesale" className='header__wholesale'>Оптовый каталог</Link> */}
        </div>
        {!loggedIn&&
          <div className="header__profile">
          {/* <Link to="/signup" className="header__link-registration">Заявка на регистрацию</Link> */}
          <button className="header__link-login" onClick={() => {navigate("/signin")}}>Войти</button>
        </div>}
        {loggedIn&&
          <div className="header__profile">
            <p className="header__link-registration">{currentUser.email}</p>
            <button className="header__link-login" onClick={signOut}>Выйти</button>
          </div>
        }
      </div>
      

      <div className='header__container2'>
        <div className='header__social-block'>
        <Link to="/" className='header__logo'><img src={logo} alt='Логотип' className='header__logo-img'/></Link>
          {!loggedIn&&
          <div className="header__profile">
          {/* <Link to="/signup" className="header__link-registration">Заявка на регистрацию</Link> */}
          <button className="header__link-login" onClick={() => {navigate("/signin")}}>Войти</button>
        </div>}
        {loggedIn&&
          <div className="header__profile">
            <p className="header__link-registration">{currentUser.email}</p>
            <button className="header__link-login" onClick={signOut}>Выйти</button>
          </div>
        }
        </div>
        <div className='header__contact-block'>
          <a href="tel:+79064744741" className='header__info'><img src={ph} className='header__icon' alt='Телефон'></img></a>
          <a href='https://t.me/ovomarketkmv' className="header__social-link" target="_blank"  rel="noreferrer"><img src={tg} className='header__icon' alt='Ссылка телеграмм'></img></a>
          <a href=' https://api.whatsapp.com/send?phone=79064744741' className="header__social-link" target="_blank"  rel="noreferrer"><img src={whatsApp} className='header__icon' alt='Ссылка телеграмм'></img></a> 
        {/* <Link to="/wholesale" className='header__wholesale'>Оптовый каталог</Link> */}
          
        </div>
        <p className='header__info header__info-open'>c 9-00 до 21-00</p>
      </div>
    </header>
  );
}
export default Header;


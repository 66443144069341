const bannerList = [
  {
    id: 15,
    description: "Картофель",
    link: "/images/potato.png",
  },
  {
    id: 13,
    link: "/images/onion.png",
    description: "Лук"
  },
  {
    id: 59,
    link: "/images/strawberry.png",
    description: "Клубника"
  },
];

const BASE_URL = 'https://www.ovo-m.ru/api';

//const BASE_URL = 'http://127.0.0.1:3001/api'

export  {
  bannerList,
  BASE_URL
}
import React, {useState, useEffect} from 'react';
import ProductCard from '../ProductCard/ProductCard';
import './ProductCatalog.css';
import { useResize } from "../utils/use-resize";
import CartBlock from '../CartBlock/CartBlock';
import FormSearch from '../FormSearch/FormSearch';
import CatalogGroup from './CatalogGroup/CatalogGroup';
import Preloader from '../Preloader/Preloader';

function ProductCatalog({products, isLoading, groups, handleSubmitFormSearch, addOrder, setCard, loggedIn, orders, strSearch, classCart, addClass, wholesale}) {
  const [valueGroup, setValueGroup] = useState("ВсеКатегории"); //Устанавливаем выбранную группу
  const [value, setValue] = useState(strSearch); // Значения поля поиска
  const {width, cardsLoad, numberOfItems} = useResize()
  const [loadedСards, setLoadedСards] = useState(cardsLoad);
  const inputRef = React.useRef()
  
  const priceTotal = orders.reduce((x,y)=>{
    return x + y.sum
  },0)
  
  useEffect(()=> {
    setValue(strSearch)
  }, [strSearch])

  useEffect(()=> {
    setLoadedСards(cardsLoad)
  },[cardsLoad])
  
  function showMoreCards() {
    setLoadedСards(loadedСards + numberOfItems)
  }

  function handleChange(e) {
    setValue(e.target.value);
    handleSubmitFormSearch(e.target.value, valueGroup)
  }
   
   function chengeValue(e) {
      setValueGroup(e.target.value);
      handleSubmitFormSearch(value, e.target.value)
   }

   function cancelSearch () {
    inputRef.current.checked=true;
    setValue("");
    setValueGroup("ВсеКатегории");
    handleSubmitFormSearch("", "ВсеКатегории")
   }
 

  return (   
    <section className={`product-catalog ${isLoading?"movie-elements_visible":""}`}>
      {width<500 && <CartBlock priceTotal={priceTotal} classCart={classCart} />}
      <div className='product-catalog__menu-container'>
        {!wholesale &&  <div className='product-catalog__menu'><h2 className="product__title" id="about-us">Каталог товаров</h2></div>}
        <FormSearch value={value} handleChange={handleChange}/>
        <button className='cancel-search' onClick={cancelSearch}>Отменить поиск</button>
      </div>
    
      <div className='product__container'>
        {!wholesale && 
          <CatalogGroup groups={groups} chengeValue={chengeValue} inputRef={inputRef}/>}
          <div className='product-catalog-find'>
        {width>500 && <CartBlock priceTotal={priceTotal} classCart={classCart} />}
        {<Preloader isLoading={isLoading}/>}
        {!isLoading &&
          <>
           {products.length === 0 &&
            <p>Товары не найдены</p>}
            <ul className="elements-list"> 
              {!wholesale &&
                products.slice(0, loadedСards).map((product) => {
                  return(<ProductCard key={product._id} card={product} addOrder={addOrder} setCard={setCard} loggedIn={loggedIn} addClass={addClass} wholesale={wholesale}/>)              
                })
              }
              {wholesale &&
                products.map((product) => {
                  if(product.wholesaleShow) {
                    return(<ProductCard key={product._id} card={product} addOrder={addOrder} setCard={setCard} loggedIn={loggedIn} addClass={addClass} wholesale={wholesale}/>)
                  }             
                })
              }

        
            </ul>
            <button className={`product-catalog__button-yet ${loadedСards>=products.length?"product-catalog__button-yet_visible_no":""}`} type ="button" onClick={showMoreCards}>ЕЩЕ</button>
          </>
        }
        </div>
      </div>   
    </section>
  );
}
export default ProductCatalog;
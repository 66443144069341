import "./AboutUs.css"

function AboutUs() {

  return (
    <section className="about-us">
      <h2 className="about-us__title" id="about-us">О нас</h2>
      <div className="about-us__line"></div>
      <div className="about-us__container">
          <p>Мы осуществляем доставку продуктов питания, свежих овощей и фруктов по г. Кисловодску. Cотрудничаем с проверенными поставщиками и гарантируем качество и свежесть продуктов. Наша служба доставки работает оперативно и аккуратно, помогут с выбором продуктов. Закажите доставку продуктов на дом и наслаждайтесь качеством и удобством нашего сервиса!</p>
      </div>
    </section>
  )
}

export default AboutUs
import "./Delivery.css"

function Delivery({isLine}) {

  return (
    <div className="delivery">
      <h2 className="delivery__title" id="delivery">Условия доставки</h2>
      {isLine &&
      <div className="delivery__line"></div>
      }  
      <div className="delivery__container">
          <p className="delivery__text">Заказ на сайте и доставка товаров осуществляется в следующем порядке:</p>
          <p className="delivery__text">1. После создания заявки менеджер связывается для уточнения деталей, количества и ассортимента.</p>
          <p className="delivery__text">2. После согласования и подтверждения, курьер выезжает по указанному адресу.</p>
          <p className="delivery__text">3. Доставка осуществляется по г. Кисловодску и составляет 100 руб., при заказе от 2000 руб. бесплатно.</p>
      </div>
    </div>
  )
}

export default Delivery
import './Group.css'
import {useFormAndValidation} from '../../hooks//useFormAndValidationIn.js'

function Group ({addGroup}) {
  let { values, handleChange,  errors, isValid} = useFormAndValidation({
    name: ''
  });
  const handleSubmit = (e) => {
    e.preventDefault();
    addGroup({
      name: values.name
    })
    values.name = ''

  }

  return (

    <form className='order-info add-group' onSubmit={handleSubmit} name="form-order-info" noValidate>
      <input className='order-info__input order-info__input-adress'
        name="name"
        value={values.name}
        onChange={handleChange}
        required
        placeholder="Название группы"
      />
      <button type="submit"
        className={`cart__button-submit ${( !isValid) ?"cart__button-submit_status_disabled":""}`}>
        Добавить
      </button>
    </form>

  )
}

export default Group;
import React, {useState} from 'react';
import './ProductCard.css'
import Count from './Count/Count';
import { useNavigate } from "react-router-dom";
import { BASE_URL } from '../utils/constants';
//import addIcon from '../../images/add_icon.svg';
import addIcon from '../../images/shoppingcart_1.png';

function Card({card, addOrder, loggedIn, addClass, wholesale}) {
  const [cardCount, setCardCount] =  useState(0);
  const [classAnimation, setClassAnimation] = useState('')
  const navigate = useNavigate();

	const increase = () => {
		setCardCount(Number(cardCount)  + 1)
	}

	const decrease = () => {
    if(cardCount >= 1) {
    setCardCount(Number(cardCount) - 1)
    }
	}

	const changeValue = (value) => {
    const val = Number(value)
    // console.log(value.target.value)
    if(card.unit.name === 'шт') {
      if(!Number.isInteger(val)) {
      }
      else {
        setCardCount(value) 
      }
    }
    else {
      setCardCount(value) 
    }
	}

  const buttonBuyHandler =()=> {
    setClassAnimation('scale-button');
    setTimeout(()=> setClassAnimation(''), 200)
    addClass() // Добавляем анимацию значку корзины
    // card.count = card.count+ Number(cardCount);
    // card.priceTotal = card.count * (!wholesale?card.price: card.wholesalePrice)
    addOrder({
      id: card._id,
      name: card.name,
      count: cardCount,
      sum: cardCount * (!wholesale?card.price: card.wholesalePrice),
      price: !wholesale?card.price: card.wholesalePrice
    })
  }

  const buttonEditHandler = () => {
    navigate('/edit-card', {state: card})
  }
  const putHandler = () => {
    navigate('/product', {state: card})
  }

  return (
    <li className="element">
      <img src={`${BASE_URL}${card.link}`} className="element__image" alt={card.name} onClick={putHandler}/>
      <div className="element__group">
        <p className="element__title"  onClick={putHandler}>{card.name}</p>
        <div className='element__price-block'>
          <p className="element__price">{!wholesale ? card.price : card.wholesalePrice } руб/{card.unit.name}</p>
          {loggedIn && <button className={`element__edit-butto ${card.isShow && "isShow"}`} onClick={buttonEditHandler}>✎</button>}
        </div>
        <div className='element__price-block'>
          <Count increase={increase} decrease={decrease} changeValue={changeValue} id={1} count={cardCount} />
          <button 
            className={`element__buy-button ${cardCount===0 && "element__buy-button_status_disabled"} ${classAnimation}`} 
            onClick={buttonBuyHandler} 
            disabled={cardCount===0}>
              <img src={addIcon} alt='Кнопка добавления' className='buy-button-img'/>
          </button>
        </div>
      </div> 
      <div className={`element__order-info ${classAnimation}`}>Добавлен</div>
    </li>
  );
}
export default Card;
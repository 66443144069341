import "./CountCart.css"
import React from 'react'
import editInc from '../../../images/editInc.svg';
import editDec from '../../../images/editDec.svg';


// const CountCart = ({ count, increase, decrease, changeValue, cardCount }) => {
const CountCart = ({ product, handleChangeQuantity}) => {

	const [cardCountCart, setCardCountCart] =  React.useState(Number(product.count));

	const increase = () => {
		setCardCountCart(Number(cardCountCart) + 1)
		handleChangeQuantity(product, true)
	}

	const decrease = () => {
		if(cardCountCart >= 1 ) {
			setCardCountCart(Number(cardCountCart) - 1)
			handleChangeQuantity(product, false)
		}
	}
		
		const changeValue = (value) => {
			if (value<=0) {
				return
			}
			setCardCountCart(Number(value))
			handleChangeQuantity(product, false, value)
		}

	return (
		<>
			<div className='count'>
				<button type='button' className='count__button-cart' onClick={decrease}><img src={editDec} alt='Уменьшить' className='count__button-img'/></button>
				<input onChange={(e)=>{changeValue(e.target.value)}} type='number' className='count__input-cart' min='1' max='100' value={Number(cardCountCart)} />
				<button type='button' className='count__button-cart' onClick={increase}><img src={editInc} alt='Увеличить' className='count__button-img'/></button>
			</div>
			<div className='product__price'>
				{product.price * product.count} руб.
			</div>
		</>
	
	);
};

export default CountCart;
import { useLocation } from "react-router-dom";
import { BASE_URL } from '../utils/constants';
import './Card.css'
import React, {useState} from 'react';
import Count from '../ProductCard/Count/Count';
import CartBlock from "../CartBlock/CartBlock";

function Product ({ addClass, classCart, orders, addOrder}) {
  const [cardCount, setCardCount] =  useState(0);
	const increase = () => {
		setCardCount(Number(cardCount)  + 1)
	}

	const decrease = () => {
    if(cardCount >= 1) {
    setCardCount(Number(cardCount) - 1)
    }
	}

	const changeValue = (value) => {
    setCardCount(value)  
}
  const { state } = useLocation();
  const priceTotal = orders.reduce((x,y)=>{
    return x + y.priceTotal
  },0)

  const buttonBuyHandler =()=> {
    if (orders.length>0) {
      orders.forEach(element => {
        if(state._id === element._id) {
          element.count = element.count + Number(cardCount)
          element.priceTotal = element.count * element.price
          addOrder(element)
          addClass()
        }
        else {
          state.count = state.count+ Number(cardCount);
          state.priceTotal = state.count * state.price;
          addOrder(state)
          addClass() // Добавляем анимацию значку корзины
        } 
      });
    }
    else {
      state.count = state.count+ Number(cardCount);
      state.priceTotal = state.count * state.price;
      addOrder(state)
      addClass() // Добавляем анимацию значку корзины
    } 
  }
  
  return(
    <div className="card">
      <CartBlock priceTotal={priceTotal} classCart={classCart} />
      <div className="card-container"> 
        <img src={`${BASE_URL}${state.link}`} className="card__image" alt={state.name}/>
        <div className="card__group">
          <p className="card__title">{state.name}</p>
          <div className='element__price-block card-price'>
            <p className="element__price card-price-n">{state.price} руб/{state.unit.name}</p>
          </div>
          <div className='element__price-block card-price'>
            <Count increase={increase} decrease={decrease} changeValue={changeValue} id={1} count={cardCount} />   
          </div>
        <button 
              className={`card__buy-button ${cardCount===0 && "element__buy-button_status_disabled"}`}
               onClick={buttonBuyHandler} 
              disabled={cardCount===0}> Добавить в корзину
            </button>
        </div> 
      </div>
      
 
    </div>
  )
}

export default Product;
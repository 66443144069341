import './Footer.css'

function Footer() {

  return (
    <footer className="footer" >
      <p className='footer__text'>Кисловодск {new Date().getFullYear()} г.</p>
    </footer>
  )
}

export default Footer